/* eslint-disable no-console */
/* eslint-disable max-len */
/* globals zoomSdk */
import React, {
  useEffect, createContext, useState, useContext, useCallback, useRef
} from 'react';
import { useRouter } from 'next/router';

import {
  doc,
  setDoc,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';

import db from '@services/firebase-service';

import {
  getAuth, setAuth
} from '@services/identity.service';

import { logsToAwsCloudWatch } from '@services/logger.service';

import { TeamsDataContext } from '@components/context/TeamsContext';
import { StreamDataContext } from '@components/context/StreamContext';

import { isZoomApp, isMeetingContext, isHostOrCoHost, getResourceType } from '@zoom-app/helpers';
import { zoomAuthCallback, getZoomChallengeCode, getZoomResource } from '@services/zoom';

import {
  createZoomPlatform
} from '@services/youtube-platform.service';

import {
  getGuestLoginToken
} from '@services/email-auth.service';

import { invokeZoomAppsSdk } from '@zoom-app/apis';
import Loader from '@atoms/Atom/Loader';

export const ZoomDataContext = createContext();

let once = 0;

export const ZoomDataProvider = ({ children }) => {
  const router = useRouter();
  let fsUnsub;

  const intervalRef = useRef();
  const metaRef = useRef();
  const meetingRef = useRef();

  const { teamContext, initTeam, user } = useContext(TeamsDataContext);
  const { setZoomMeetingStatus } = useContext(StreamDataContext);

  const [, setError] = useState(null);
  const [runningContext, setRunningContext] = useState();
  const [connected, setConnected] = useState(false);
  const [counter, setCounter] = useState(0);
  const [preMeeting, setPreMeeting] = useState(true); // start with pre-meeting code
  const [, setUserContextStatus] = useState('');
  const [userContext, setUserContext] = useState();
  const [headerContext, setHeaderContext] = useState();
  const [zoomPlatform, setZoomPlatform] = useState();
  const [screenShare, setScreenShare] = useState(false);
  const [expandState, setExpandState] = useState({ action: 'collapse' });
  const [meetingInfo, setMeetingInfo] = useState();
  const [meetingContext, _setMeetingContext] = useState();
  const [metaData, _setMetaData] = useState();
  const [uid, setUid] = useState();
  const [meetingInitializing, setMeetingInitializing] = useState(true);
  const [clientInfo, setClientInfo] = useState({});
  const [template, _setTemplate] = useState();
  const [displayAuthPopup, setDisplayAuthPopup] = useState(false);

  const templateRef = useRef();
  const shouldSendTemplateRef = useRef(false);
  const challengeCode = useRef();
  const authorizationStatus = useRef(false);

  const setTemplate = (data) => {
    templateRef.current = { ...data };
    _setTemplate({ ...data });
  };

  const setChallengeCode = (code) => {
    challengeCode.current = code;
  };

  const setShouldSendTemplate = (flag) => {
    shouldSendTemplateRef.current = flag;
  };

  const setMeetingContext = (data) => {
    meetingRef.current = { ...data };
    _setMeetingContext({ ...data });
  };

  const setMetaData = (data) => {
    metaRef.current = { ...data };
    _setMetaData(data);
  };

  const logToCloudWatch = (logData, logMessage = 'ZoomApp Log', level = 'INFO') => {
    const auth = getAuth();
    const body = {
      logMessage,
      logData: { clientInfo, auth, ...logData },
      level
    };
    logsToAwsCloudWatch(body);
  };

  const getMeetingUserContext = () => {
    return new Promise((resolve, reject) => {
      invokeZoomAppsSdk({ name: 'getUserContext' })
        .then((response) => {
          setUserContext(response);
          setClientInfo((prev) => ({ ...prev, ...response }));
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  };

  const openUrl = async (url) => {
    await invokeZoomAppsSdk({
      name: 'openUrl',
      options: { url }
    });
  };

  const isLoggedIn = () => user && user.user_role !== 'guest';

  const getGreetings = () => {
    if (isLoggedIn()) {
      let name = user?.email?.split('@')[0];
      name = name[0].toUpperCase() + name.slice(1);
      return `Hi ${name} 👋🏽`;
    }
    return 'Hi Guest 👋🏽';
  };

  const getZoomPlatform = async () => {
    const res = await createZoomPlatform({ userId: user?.id, zoomId: headerContext?.uid });
    if (res.status) {
      setZoomPlatform(res.entity);
    } else {
      console.log('Get Zoom Platform error: ', res, user?.id);
    }
  };

  const initGuest = async () => {
    const data = await getGuestLoginToken({ email: process.env.NEXT_PUBLIC_ZM_GUEST_EMAIL });
    if (data.status) {
      setAuth(data.entity[0]);
      initTeam();
    }
  };

  const getMeetingKey = () => {
    if (!zoomPlatform) throw new Error('Missing Zoom Platform');
    if (!meetingRef?.current?.meetingUUID) throw new Error('Missing Meeting UUID');
    const uuid = meetingRef.current?.meetingUUID?.replace(/[-/.]/g, '');
    return `${zoomPlatform.id}:${uuid}`;
  };

  const setResetFS = async () => {
    try {
      const docRef = await doc(db, 'zoomapp-meetings', getMeetingKey());
      await setDoc(docRef, { createdAt: new Date().toISOString() });
    } catch (error) {
      logToCloudWatch({ error }, 'Error setting metadata', 'ERROR');
    }
  };

  const updateMetaDataFS = (data) => {
    const docRef = doc(db, 'zoomapp-meetings', getMeetingKey());
    updateDoc(docRef, data)
      .catch((error) => {
        logToCloudWatch({ error }, 'Error updating metadata', 'ERROR');
      });
  };

  const getResourceInfoFromApi = async (resourceId) => {
    if (!zoomPlatform?.is_authorized || !isMeetingContext(runningContext)) return;
    const queryParams = {
      platformId: zoomPlatform.id
    };
    const res = await getZoomResource(getResourceType(runningContext), resourceId, queryParams);
    return res.entity;
  };

  const getActiveMeetingInfo = async (userContext) => {
    if (!userContext) return {};

    const allApis = [
      invokeZoomAppsSdk({ name: 'getMeetingUUID' })
    ];

    if (isHostOrCoHost(userContext.role)) {
      allApis.push(invokeZoomAppsSdk({ name: 'getMeetingContext' }));
      allApis.push(invokeZoomAppsSdk({ name: 'getMeetingJoinUrl' }));
    }

    const [meetingUUIDRes, meetingContextRes, joinMeetingUrlRes] = await Promise.all(allApis);

    const data = {};

    if (meetingUUIDRes?.meetingUUID) {
      data.meetingUUID = meetingUUIDRes.meetingUUID;
    }

    if (meetingContextRes?.meetingTopic) {
      data.meetingId = meetingContextRes.meetingID;
      data.title = meetingContextRes.meetingTopic;
    }

    if (joinMeetingUrlRes?.joinUrl) {
      data.joinUrl = joinMeetingUrlRes.joinUrl;
    }
    if (data.meetingId) {
      const apiResource = await getResourceInfoFromApi(data.meetingId);
      if (apiResource) {
        // meeting/webinar info fetched from Zoom API
        data.apiResource = apiResource;
      }
    }

    setMeetingInfo(data);
    setMeetingInitializing(false);
  };

  const checkAuth = () => {
    if (authorizationStatus.current) return;
    if (zoomPlatform?.isRegEnabled && clientInfo && !zoomPlatform.is_authorized) {
      setDisplayAuthPopup(true);
    }
  };

  const authorize = async () => {
    authorizationStatus.current = true;
    const res = await getZoomChallengeCode();
    setChallengeCode(res.entity.challengeCode);
    zoomSdk.authorize({
      state: res.entity.state,
      codeChallenge: res.entity.challengeCode
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isZoomApp()) return;
    async function configureSdk() {
      // to account for the 2 hour timeout for config
      const configTimer = setTimeout(() => {
        setCounter(counter + 1);
      }, 120 * 60 * 1000);

      try {
        const capabilities = [
          'getUserContext',
          'connect',
          'onConnect',
          'postMessage',
          'onMessage',
          'onShareApp',
          'onExpand',
          'onAuthorized',
          'promptAuthorize',
          'showNotification',
          'joinMeeting',
          'launchAppInMeeting',
          'openUrl',
          'onRunningContextChange'
        ];

        const configResponse = await zoomSdk.config({
          capabilities,
          version: '0.16.14',
          popoutSize: { height: 1080, width: 1920 }
        });

        setClientInfo((prev) => ({ ...prev, ...configResponse }));

        setRunningContext(configResponse.runningContext);

        setUserContextStatus(configResponse.auth.status);

        zoomSdk.onShareApp((action) => {
          setScreenShare(action === 'start');
        });

        zoomSdk.onExpandApp((event) => {
          setExpandState(event);
        });

        zoomSdk.onAuthorized(async (event) => {
          await zoomAuthCallback({ ...event, verifier: challengeCode.current });
          authorizationStatus.current = false;
          setDisplayAuthPopup(false);
          setZoomPlatform(null);
          getZoomPlatform();
        });

        zoomSdk.onRunningContextChange(({ runningContext }) => {
          setRunningContext(runningContext);
        });
      } catch (err) {
        // console.log(err);
        setError('There was an error configuring the JS SDK');
      }
      return () => {
        clearTimeout(configTimer);
      };
    }
    configureSdk();
  }, [counter]);

  async function sendMessage(msg) {
    try {
      await zoomSdk.postMessage({
        payload: msg,
        sender: isMeetingContext(runningContext) ? 'meeting' : 'client'
      });
    } catch (e) {
      console.log('Error sending message', e);
    }
  }

  // PRE-MEETING
  const onMessageHandlerClient = useCallback((message) => {
    const content = message.payload.payload;
    if (content === 'connected' && preMeeting === true) {
      setPreMeeting(false); // client instance is finished with pre-meeting
      setConnected(true);
      if (templateRef.current && shouldSendTemplateRef.current) {
        setShouldSendTemplate(false);
        sendMessage({ template: { ...templateRef.current, autoStart: true } });
      }
    }

    if (message.payload.sender === 'meeting' && message.payload.payload?.template) {
      setTemplate({ ...message.payload.payload.template, autoStart: false });
    }

    if (content?.action === 'disconnect') {
      setConnected(false);
      setPreMeeting(true);
    }
  }, [template, preMeeting, runningContext]);

  const receiveMessage = useCallback(
    () => {
      const onMessageHandler = async (message) => {
        const content = message.payload.payload;

        if (content?.token) {
          if (content) {
            await setAuth(content);
            initTeam();
          } else {
            initGuest();
          }
        }
      };
      zoomSdk.addEventListener('onMessage', onMessageHandler);
      if (once === 0) {
        zoomSdk.addEventListener('onMessage', onMessageHandler);
        once = 1;
      }
    },
    [router]
  );

  // PRE-MEETING
  useEffect(() => {
    if (!isMeetingContext(runningContext) && runningContext) {
      zoomSdk.addEventListener('onMessage', onMessageHandlerClient);
    }
  }, [template, onMessageHandlerClient, preMeeting, runningContext]);

  useEffect(() => {
    if (isMeetingContext(runningContext)) {
      getMeetingUserContext();
    } else {
      setMeetingInitializing(false);
    }
  }, [runningContext]);

  useEffect(() => {
    if (userContext && zoomPlatform) {
      getActiveMeetingInfo(userContext);
    }
  }, [userContext, zoomPlatform]);

  useEffect(() => {
    async function connectInstances() {
      // only can call connect when in-meeting
      if (isMeetingContext(runningContext)) {
        zoomSdk.addEventListener('onConnect', () => {
          setConnected(true);
          // PRE-MEETING
          // first message to send after connecting instances is for the meeting
          // instance to catch up with the client instance
          if (preMeeting === true) {
            // console.log('Letting client know meeting instance exists.');
            sendMessage('connected', 'meeting');
            // console.log('Adding message listener for client\'s current state.');
            const onMessageHandlerMtg = (message) => {
              // console.log(
              //   'Message from client received. Meeting instance updating its state:',
              //   message
              // );

              if (message.payload.sender === 'client' && message.payload.payload?.template) {
                const tmpl = message.payload.payload.template;
                if (tmpl.sender === 'client') {
                  setTemplate(tmpl);
                }
              }
              // router.push(message.payload.payload);
              // zoomSdk.removeEventListener('onMessage', onMessageHandlerMtg);
              setPreMeeting(false); // meeting instance is finished with pre-meeting
            };
            zoomSdk.addEventListener('onMessage', onMessageHandlerMtg);
          }
        });
        await zoomSdk.connect();
      }
    }
    if (connected === false) {
      connectInstances();
    }
  }, [connected, preMeeting, runningContext, template]);

  // POST-MEETING
  useEffect(() => {
    async function communicateTabChange() {
      // only proceed with post-meeting after pre-meeting is done
      // just one-way communication from in-meeting to client
      if (isMeetingContext(runningContext) && connected && preMeeting === false) {
        sendMessage(router.pathname, runningContext);
      } else if (runningContext === 'inMainClient' && preMeeting === false) {
        receiveMessage(runningContext, 'for tab change');
      }
    }
    communicateTabChange();
  }, [connected, preMeeting, receiveMessage, runningContext]);

  useEffect(() => {
    if (connected) {
      sendMessage(getAuth());
    }
    if (!getAuth() && isZoomApp()) {
      initGuest();
    }
  }, [teamContext]);

  useEffect(() => {
    if (headerContext && user) {
      getZoomPlatform();
    }
  }, [headerContext?.uid, user]);

  const updateHb = () => {
    updateMetaDataFS(
      {
        clientIsAlive: true,
        clientLastHeartBeat: new Date().getTime()
      }
    );
  };

  const stopDataSync = () => {
    clearInterval(intervalRef.current);
    if (fsUnsub?.unsub) {
      fsUnsub.unsub();
    }
  };

  const startDataSync = async () => {
    await setResetFS();
    fsUnsub = {
      unsub: onSnapshot(
        doc(db, 'zoomapp-meetings', getMeetingKey()),
        (docu) => {
          if (docu.exists()) {
            const data = docu.data();
            setMetaData(data);
          }
        },
        (error) => {
          logToCloudWatch({ error }, 'Error subscribing to metadata', 'ERROR');
          setTimeout(() => {
            startDataSync();
          }, 3000);
        }
      )
    };

    updateHb(); // initial hb.
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (metaRef?.current?.botStatus?.errorCode) {
        setZoomMeetingStatus({ ...metaRef.current.botStatus });
        stopDataSync();
        return;
      }
      updateHb();
    }, 10000);
  };

  useEffect(() => {
    if (metaData?.botLastHeartBeat && metaData?.botLastHeartBeat < (new Date().getTime() - 60000)) {
      setZoomMeetingStatus({ type: 'leave', isInMeeting: false });
      stopDataSync();
    }
  }, [metaData]);

  // const notifyTemplateChange = async (template) => {
  //   try {
  //     await sendMessage({ template });
  //   } catch (e) {
  //     setConnected(false);
  //     setPreMeeting(true);
  //   }
  // };

  // useEffect(() => {
  //   if (template) {
  //     notifyTemplateChange(template);
  //   }
  // }, [template]);

  useEffect(() => {
    if (isLoggedIn()) {
      checkAuth();
    }

    return () => {
      authorizationStatus.current = false;
    };
  }, [zoomPlatform, clientInfo]);

  useEffect(() => {
    stopDataSync();
  }, []);

  if (isMeetingContext(runningContext) && !meetingInfo) {
    return (
      <div className="h-screen">
        <Loader messages={['Getting Meeting Info']} />
      </div>
    );
  }

  return (
    <ZoomDataContext.Provider
      value={{
        connected,
        sendMessage,
        runningContext,
        zoomPlatform,
        userContext,
        screenShare,
        invokeZoomAppsSdk,
        initGuest,
        openUrl,
        isLoggedIn,
        getGreetings,
        expandState,
        meetingContext,
        setMeetingContext,
        headerContext,
        setHeaderContext,
        metaData,
        setMetaData,
        updateMetaDataFS,
        getMeetingKey,
        startDataSync,
        stopDataSync,
        uid,
        setUid,
        getActiveMeetingInfo,
        meetingInitializing,
        setMeetingInitializing,
        clientInfo,
        logToCloudWatch,
        template,
        setTemplate,
        setShouldSendTemplate,
        meetingInfo,
        setMeetingInfo,
        challengeCode,
        setChallengeCode,
        displayAuthPopup,
        setDisplayAuthPopup,
        authorize
      }}
    >
      {children}
    </ZoomDataContext.Provider>
  );
};
