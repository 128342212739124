import Button from '@atoms/new/button';
import { TeamsDataContext } from '@components/context/TeamsContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { inviteTeamMember } from '@services/youtube-platform.service';
import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { yupValidator } from '@lib/yup-validator';
import { useForm } from 'react-hook-form';
import { notify } from '@components/Presentation/helpers';
import Input from '@atoms/Input';
import Tooltip from '@atoms/new/tooltip';
import { useRouter } from 'next/router';
import { useDisclosure } from '@nextui-org/react';
import { PricingAlertNudgePopover } from '@components/PricingPlans/NudgesAlert';

interface InviteMemberProps {
  teams: any;
  onSuccess: () => void;
  isOwner: boolean;
}
export default function InviteMember({
  teams,
  onSuccess,
  isOwner,
}: InviteMemberProps) {
  const isMaxedInvites =
    teams?.members?.length >= teams?.purchase_plan?.sub_users_allowed;
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        email: yup
          .string()
          .required('Please enter email.')
          .email('Please enter a valid email.'),
        role: yup
          .string()
          .required('Please select a valid role')
          .default('editor'),
      })
    ),
  });

  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const {
    isOpen: isUpgradeAlertOpen,
    onOpen: onUpgradeAlertOpen,
    onClose: onUpgradeAlertClose,
  } = useDisclosure();

  const { updateTeam, ownTeam, setTeamMessages } = useContext(TeamsDataContext);

  const inviteMember = async (data: any) => {
    if (isMaxedInvites) {
      onUpgradeAlertOpen();
      return;
    }
    if (!ownTeam) return;
    setLoading(true);
    const response = await inviteTeamMember(ownTeam.billing_account.id, data);
    if (response.status) {
      updateTeam(response.entity);
      onSuccess();
      setTeamMessages([response.message]);
      notify(response.message);
      resetField('email');
    } else {
      setError('email', { type: 'custom', message: response.message });
    }
    setLoading(false);
  };
  return (
    <>
      <p className="mt-8 font-semibold">Invite others to this workspace</p>
      {isMaxedInvites && (
        <div className="mt-4 flex items-center gap-[15px] bg-pure-orange-50 border-pure-orange-600 border rounded-md px-4 py-3.5 text-sm">
          <InfoOutlinedIcon fontSize="small" color="warning" />
          <p>
            Workspace capacity reached. Remove a member before adding another.
          </p>
        </div>
      )}
      <p className="text-sm text-grey-700 font-semibold mt-4">
        Invite by email address
      </p>
      <div>
        <div className="mt-2 flex space-x-2">
          <Tooltip
            content="Only admins can invite others. Ask your admin for help inviting others."
            isDisabled={isOwner}
            placement="top-start"
          >
            <Input
              {...register('email')}
              size="lg"
              placeholder="Add people"
              isInvalid={!!errors.email}
              disabled={!isOwner}
            />
          </Tooltip>
          <PricingAlertNudgePopover
            placement='bottom-end'
            title="Capacity reached"
            description="Upgrade your plan to have more members in your workspace."
            trigger={<Button
              onClick={handleSubmit(inviteMember)}
              isLoading={loading}
              isDisabled={!isOwner}
            >
              Invite
            </Button>
            }
            isOpen={isUpgradeAlertOpen}
            onClose={onUpgradeAlertClose}
          />
        </div>
        {isMaxedInvites && (
          <div className="text-sm text-grey-700 font-normal mt-4">
            You have 0 seats left.{' '}
            <span className="text-blue-500 cursor-pointer" onClick={() => {
              router.push('/pricing');
            }}>Upgrade</span>
          </div>
        )}
      </div>
      {errors.email && (
        <p className="mt-2 text-pure-red-600 text-sm">
          {errors.email.message as string}
        </p>
      )}
    </>
  );
}
