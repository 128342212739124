import Button from '@atoms/new/button';
import { NoticeBanner } from '@atoms/new/NoticeBubble';
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import { useRouter } from 'next/router';

type OverlayPlacement = 'top' | 'bottom' | 'right' | 'left' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end' | 'right-start' | 'right-end';

interface PricingAlertNudgeProps {
  title: string;
  description: string;
  onClose: () => void;
}

export const PricingAlertNudge = ({ title, description, onClose }: PricingAlertNudgeProps) => {
  const router = useRouter();

  return (
    <div>
      <NoticeBanner variant='warning'>
        <div className='flex flex-col gap-2'>
          <p className='text-sm font-semibold'>{title}</p>
          <p className='text-sm font-normal mb-2'>{description}</p>
          <Button className='w-fit' kind='secondary' onClick={() => router.push('/pricing')}>Upgrade</Button>
        </div>
      </NoticeBanner>
    </div>
  );
};

interface PricingAlertNudgePopoverProps extends PricingAlertNudgeProps {
  trigger: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  placement?: OverlayPlacement;
  offset?: number;
  disabled?: boolean;
  crossOffset?: number;
}

export const PricingAlertNudgePopover = ({
  title,
  description,
  trigger,
  isOpen,
  onClose,
  placement = 'bottom-end',
  offset = 7,
  crossOffset = 0
}: PricingAlertNudgePopoverProps) => {
  return (
    <Popover
      classNames={{ content: 'p-0', trigger: 'aria-expanded:opacity-100 aria-expanded:scale-100' }}
      color='warning'
      placement={placement}
      isOpen={isOpen}
      crossOffset={crossOffset}
      onClose={onClose}
      offset={offset}
    >
      <PopoverTrigger>
        {trigger}
      </PopoverTrigger>

      <PopoverContent>
        <PricingAlertNudge
          title={title}
          description={description}
          onClose={() => {}}
        />
      </PopoverContent>
    </Popover>
  );
};
