import moment from 'moment';
import Image from 'next/image';
import router from 'next/router';
import { useEffect, useState } from 'react';
import { onValue, ref } from 'firebase/database';
import { useQuery } from '@tanstack/react-query';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { platformImages } from '@molecules/CardList';
import { usePopupContext } from '@components/context/screenShotPupUpContext';
import { getAuth } from '@services/identity.service';
import { realTimeDb } from '@services/firebase-service';
import { getLiveStreamByIdClient } from '@services/youtube-platform.service';
const ScreenShotsPopup = () => {
  const auth = getAuth();
  const [images, setImages] = useState(['']);
  const [imageStatus, setImageStatus] = useState('in-process');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { hidePopup, streamId, updateStreamId } = usePopupContext();
  const { data, refetch, isLoading } = useQuery(
    {
      queryKey: ['screenshots', {}],
      queryFn: () => getLiveStreamByIdClient(streamId, auth),
    },
  );
  useEffect(() => {
    if (!streamId) return;
    const db = ref(realTimeDb, `screenshots/${streamId}`);
    onValue(db, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data?.stage === 'completed') {
          refetch();
          setImageStatus(data?.stage);
        }
      }
    });
  }, []);
  useEffect(() => {
    const screenshotsData = data?.entity?.screenshots.filter((sr: any) => Object.keys(sr).length !== 0).map((jsonString: any) => JSON.parse(jsonString));
    setImages(screenshotsData?.filter(
      (sr: any) => !['xlsx', 'scores', 'attendance'].includes(sr?.id)).map((image: any) => image.url)
    );
  }, [data]);
  useEffect(() => {
    if (data?.entity?.allowed_commenters === undefined) return;

    if (Object?.keys(data?.entity?.allowed_commenters)?.length === 0) { hidePopup(true); return; }

    if (imageStatus !== 'completed') return;
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % (images?.length + 1));
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [images, data?.entity]);

  if (isLoading) return null;

  return (
    <main className='w-[275.445px] h-[369px] bg-white absolute right-4 bottom-10 z-50 shadow-md rounded-md flex flex-col'>
      <section className='w-full h-[148px] relative rounded-t-md'>
        <div>
          {imageStatus === 'completed' ?
            <>
              {images[currentImageIndex] ?
                <Image
                  fill
                  alt="Carousel"
                  src={images[currentImageIndex]}
                  className='w-full h-auto rounded-t-md'
                />
                : <div className='w-full h-[148px] flex flex-col items-center justify-center' style={{ background: 'linear-gradient(105deg, #FF0064 -36.08%, #000 40.49%, #0086FF 131.87%)' }}>
                  <p className='text-white font-semibold text-md whitespace-nowrap overflow-ellipsis overflow-hidden px-4'>
                    {data?.entity?.title && data?.entity.title }
                  </p>
                  <p className='text-white text-sm whitespace-nowrap overflow-ellipsis overflow-hidden px-4'>
                    {data?.entity?.screenshots?.length > 0 ? data?.entity?.screenshots?.length : data?.entity?.ros_index + 3} slides
                  </p>
                </div>}
              <div className='absolute absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-[4px]'>
                {[...images, ''].map((_: any, i: number) => <>
                  <FiberManualRecordIcon className={`${currentImageIndex === i ? 'text-neutral-4' : 'text-grey-600'}`} style={{ fontSize: '4px' }}/>
                </>
                )}
              </div>
            </>
            :
            <div className='w-full h-[148px] flex flex-col items-center justify-center' style={{ background: 'linear-gradient(105deg, #FF0064 -36.08%, #000 40.49%, #0086FF 131.87%)' }}>
              <p className='text-white font-semibold text-md whitespace-nowrap overflow-ellipsis overflow-hidden px-4'>
                {data?.entity?.title && data?.entity.title }
              </p>
              <p className='text-white text-sm whitespace-nowrap overflow-ellipsis overflow-hidden px-4'>
                {data?.entity?.screenshots?.length > 0 ? data?.entity?.screenshots?.length : data?.entity?.ros_index + 3} slides
              </p>
            </div>
          }
        </div>
        <div className='p-1 bg-white absolute -top-4 -left-4 rounded-full cursor-pointer w-[38px] h-[38px] flex items-center justify-center' onClick={() => { updateStreamId(undefined); hidePopup(true); }}>
          <CloseRoundedIcon fontSize='medium'/>
        </div>
      </section>
      <section className='flex-1 p-6'>
        <h6
          className='text-xs bg-gray-200 w-fit px-2 py-1 rounded-md mb-2'>
          Reports
        </h6>
        <h5 className='text-sm mb-2'>
          {data?.entity?.start_time ? moment(data?.entity.start_time).format('MMM D, dddd・h:mm A') : ''}
        </h5>
        <h4 className='text-sm font-semibold'>
          {data?.entity?.title && data?.entity.title }
        </h4>
        <div className='flex gap-2 items-center'>
          {data?.entity?.platform_type !== '' && <Image src={platformImages[data?.entity?.platform_type as keyof typeof platformImages]} width={20} height={20} alt={`${data?.entity.platform_type}-icon`} className="mr-3 mt-6 flex-shrink-0" />}
          {data?.entity?.hybrid_platform !== null && <Image src={platformImages[data?.entity?.hybrid_platform?.toLowerCase() as keyof typeof platformImages]} width={20} height={20} alt={`${data?.entity?.hybrid_platform?.toLowerCase()}-icon`} className="mr-3 mt-6 flex-shrink-0" />}
        </div>
      </section>
      <section className='w-full h-[52px] border-t-[1px] border-gray-200 flex items-center cursor-pointer'
        onClick={() => { router.push(`/stats/${streamId}?details=true${imageStatus === 'completed' ? '&screenshots=true' : ''}`); updateStreamId(undefined); hidePopup(true); }}>
        <p className='text-blue-600 text-sm ml-6 mr-1 font-semibold'>
          {imageStatus === 'completed' ? 'View screenshots' : 'View Reports'}
        </p>
        <ArrowForwardRoundedIcon style={{ color: '#3D7EE1', fontSize: '20px' }} />
      </section>
    </main>
  );
};
export default ScreenShotsPopup;
