export const isMicrosoftTeamsApp = () => {
  return typeof window !== 'undefined' && ['embedded-page-container', 'extension-tab-frame'].includes(window.name);
};

export const microsoftTeamsBasePath = process.env.NEXT_PUBLIC_MS_TEAMS_BASE_PATH;

export const redirectToMicrosoftTeamsApp = (router) => {
  if (isMicrosoftTeamsApp()) {
    router.push(microsoftTeamsBasePath);
  }
};

export const isMeetingContext = (context) => (context?.match(/^(meetingStage|sidePanel)$/));

export const isContent = context => context === 'content';
