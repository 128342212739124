import CloseIcon from '@mui/icons-material/CloseRounded';

interface INoticeBubbleProps {
  visible?: boolean;
  onClose?: () => void;
  startContent?: React.ReactNode;
  children: React.ReactNode;
  variant?: 'info' | 'warning' | 'alert' | 'success';
}

export const NoticeBanner = ({
  children,
  onClose,
  visible = true,
  startContent = null,
  variant = 'success'
}: INoticeBubbleProps) => {
  if (!visible) return null;

  const variantStyles = {
    info: 'bg-info text-white',
    warning: 'bg-warning text-primary-900',
    alert: 'bg-alert text-white',
    success: 'bg-success text-white'
  };

  return (
    <div
      className={`justify-between p-3 flex w-[250px] rounded-md items-start ${variantStyles[variant]}`}>
      {startContent}
      {children}
      {!!onClose && <CloseIcon fontSize='small' onClick={onClose} />}
    </div>
  );
};
