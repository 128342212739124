const ExternalLink = ({ href, className = '', children }) => {
  const handleClick = async (e) => {
    e.preventDefault();
    window.open(href, '_blank');
  };

  return <a href={href} onClick={handleClick} className={`cursor-pointer ${className}`}>{children}</a>;
};

export default ExternalLink;
