import { useRouter } from 'next/router';

import { isMicrosoftTeamsApp, microsoftTeamsBasePath } from '@teams-app/helpers';

export const redirectToHomeBase = (router) => {
  if (!isMicrosoftTeamsApp()) {
    return router.push('/');
  }
  return router.push(microsoftTeamsBasePath);
};

const useRedirect = () => {
  const router = useRouter();

  const getNextParam = () => {
    return `next=/${microsoftTeamsBasePath}`;
  };

  const redirectToHome = () => redirectToHomeBase(router);

  return { redirectToHome, getNextParam };
};

export default useRedirect;
