export const isProtected = (route) => {
  const unprotectedRoutes = [
    '/auth/thankYou',
    '/auth/login',
    '/auth/signup',
    '/home',
    '/welcome',
    '/zoomTag',
    '/zoom-app/server',
    '/zoom-app',
    '/zoom-app/meeting',
    '/zoom-app/login',
    '/zoom-app/signup',
    '/zoom-app/landing',
    '/login',
    '/signup',
    '/transient',
    '/ipa/events/[slug]',
    '/my-teams/invitations/[id]',
    '/streams/[streamId]/overview',
    '/streams/[streamId]/linkLibrary',
    '/headless/login/[token]',
    '/l/choice-circle',
    '/l/emojis-everywhere',
    '/l/fantastic-fans',
    '/l/link-library',
    '/l/magic-maps',
    '/l/quick-questions',
    '/l/rating-polls',
    '/l/talking-tiles',
    '/l/text-track',
    '/l/transient-thoughts',
    '/l/winning-wheel',
    '/l/wonder-words',
    '/l/power-polls/[id]/[slug]',
    '/l/open-ended-polls',
    '/teams-app/config',
    '/teams-app/main',
    '/teams-app/login',
    '/teams-app/signup',
    '/share/[stream_id]/[screenshot_id]',
    '/l1/[link_id]'
  ];

  return !unprotectedRoutes.includes(route);
};

export const guestModeAllowed = (route) => {
  const guestModeRoutes = ['/streams/[streamId]/ros', '/zoom-app', '/zoom-app/meeting', '/zoom-app/landing', '/accessibility', '/sandbox'];
  return guestModeRoutes.includes(route);
};

export const isAdminRoute = (route) => {
  const adminRoutes = ['/admin/dashboard'];

  return adminRoutes.includes(route);
};
