import Header from './Header';
import Sidenav from './Sidenav';
import ZoomHeader from './ZoomHeader';
import Footer from './Footer';
import SmallShapeHeader from './SmallShapeHeader';
import MicrosoftTeamsHeader from './MicrosoftTeamsHeader';

export {
  Header, Sidenav, ZoomHeader, Footer, SmallShapeHeader, MicrosoftTeamsHeader
};
